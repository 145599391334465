 

import mobicab from "../../images/ShelvingSystems/mobicab-1.jpg"
import mobicab2 from "../../images/ShelvingSystems/mobicab-2.jpg"
import mobicab3 from "../../images/ShelvingSystems/mobicab-3.jpg"
import mobicab4 from "../../images/ShelvingSystems/mobicab-4.jpg"
import mobicab5 from "../../images/ShelvingSystems/mobicab-5.jpg"
import mobicab6 from "../../images/ShelvingSystems/mobicab-6.jpg"
import mobicabplus1 from "../../images/ShelvingSystems/mobicab-plus1.jpg"
import mobicabplus2 from "../../images/ShelvingSystems/mobicab-plus2.jpg"
import mobicabplus3 from "../../images/ShelvingSystems/mobicab-plus3.jpg"
import mobicabplus4 from "../../images/ShelvingSystems/mobicab-plus4.jpg"
import mobicabdd from "../../images/ShelvingSystems/mobicabdd-1.jpg"
import mobicabdd2 from "../../images/ShelvingSystems/mobicabdd-2.jpg"
import mobicabdd3 from "../../images/ShelvingSystems/mobicabdd-3.jpg"
import mobicabdd4 from "../../images/ShelvingSystems/mobicabdd-4.jpg"
import mobicabdd5 from "../../images/ShelvingSystems/mobicabdd-5.jpg"
import staticab from "../../images/ShelvingSystems/staticab1.jpg"
import staticab2 from "../../images/ShelvingSystems/staticab2.jpg"
import staticab3 from "../../images/ShelvingSystems/staticab3.jpg"
import staticab4 from "../../images/ShelvingSystems/staticab4.jpg"
import mobicab360 from "../../images/ShelvingSystems/360cab.jpg"
 const shelvingSystemData = [{
    Link:"Mobicab",
    title: "Mobicab",
    description: `OFFICE Divisions MOBICAB® is the perfect choice to gain storagespace.All you need is one aisle of only 80cm and you can almost double the storage capacity of your room.The fly -wheels mechanism can be adapted to the load capacity assuring effortless operation. For heavy base loads, a double or triple multiplier is mounted.The system is available with a variety of options to facilitate work and storage such as pull out trays, pullout filing support, dividers, drawers and sliding doors
    MOBICAB® is movable on solid rails which are fixed (or not) to the floor:
    On concrete floor
    On raised laminated floor, with Aluminum ramp for better access
    On raised Aluminum floor, which is emission free for best protection of the stored items
    MOBICAB® could be adapted to any room despite building constraints such as ducting, pillars or oblique walls.
    MOBICAB® is designed to comply with international safety standards GS TV and is manufactured according to ISO 9001: 2008 and ISO 14001: 2004. As safety is one of our priorities
    MOBICAB® is available with locking devices to prevent movement of the mobile cabinets during storage and retrieval.`,
    image:mobicab,
    sections: [
    { slide: mobicab},
    { slide: mobicab2},
    { slide: mobicab3},
    { slide: mobicab4},
    { slide: mobicab5},
    { slide: mobicab6},
    ],
  },
  {
    Link:"Mobicab-plus",
    title: "Mobicab +",
    description: `MOBICAB+ is equipped by electronic controllers that are extremely easy to operate even with full hands.Mobile bases are equipped with Motor Current Monitoring System.(MCMS)
    When the mobile base is suddenly hit by an obstacle, the motor will stop and make a backward return between 5 to 10cm. Bases are also equipped with safety sweeps (photocells) that will assure the user of maximum safety while working inside the aisle.Advantages:
    Long mobile cabinets with high loads do not cause a problem because of the “Soft Start and Stop” attribute. The speed is constant regardless of the weight or length.n addition, lighting can be integrated to the system in a way that only the needed aisle would be lit to reduce power consumption.
    The system is manufactured following international standards: UL – CE – GS TÜV.Thanks to the Soft Start, and Stop we guarantee that goods wont fall from shelves especially when you store valuables. It is also interfaced with environmental and security systems such as: lighting, humidity control, ventilation and fire alarm among other features.One touch operation even with full hands thanks to large ergonomic cleverly designed keypads
    Double sided operation
    Control aisle lighting upon operation
    Safety sweeps
    Movement detection (ADS-EDS)
    Passive security
    Movement without vibration
    System interface`,
    image:mobicabplus1,
    sections: [
    { slide: mobicabplus1},
    { slide: mobicabplus2},
    { slide: mobicabplus3},
    { slide: mobicabplus4}
    ],
  },
  {
    Link:"Mobicab-DD",
    title: "Mobicab DD",
    description: `MOBICAB DD is equipped by electronic controllers that are extremely easy to operate even with full hands.
    Mobile bases are equipped with Motor Current Monitoring System. (MCMS)When the mobile base is suddenly hit by an obstacle, the motor will stop and make a backward return between 5 to 10cm. Bases are also equipped with safety sweeps (photocells) that will assure the user of maximum safety while working inside the aisle.
    Advantages:
    No need for costly heavy-duty mezzanine structure
    Storage capacity gain up to almost four times
    Easy operation
    Mobile cabinets with high loads do not cause a problem because of the Soft Start & Stop.
    The speed is constant regardless of the weight or length.
    One touch operation even with full hands thanks to large ergonomic & cleverly designed keypads.
    In addition, lighting can be integrated to the system in a way that only the needed aisle would be lit to reduce power consumption.
    The system is manufactured following international standards: UL – CE – GS TÜV.
    Thanks to the “Soft Start, and Stop” we guarantee that goods won’t fall from shelves especially when you store valuables.
    It is also interfaced with environmental and security systems such as: lighting, humidity control, ventilation and fire alarm among other features.Double sided operation
    Control aisle lighting upon operation
    Safety sweeps
    Movement detection (ADS ; EDS)
    Passive security
    Movement without vibration
    System interface
    Motor current monitring system (MCMS)
    Stored articles exceeding the measurement of the shelves are protected from accident thanks to the user-friendly programmable CPU; the system can be programmed to stop at a certain point in order not to crash the so-called article.
    Example of a program: After working hours, the system turns to night shift and the mobile cabinets are all closed in order to prevent any intrusion. Alternatively, mobile cabinets can be opened at night to keep the system ventilated and protect stored goods on the long term.`,
    image:mobicabdd,
    sections: [
    { slide: mobicabdd},
    { slide: mobicabdd2},
    { slide: mobicabdd3},
    { slide: mobicabdd4},
    { slide: mobicabdd5}
    ],
  },
  {
    Link:"Staticab",
    title: "Staticab",
    description: `Staticab (Static Cabinet)
    Storage Cabinets made of lateral uprights, shelves, and diagonal struts.
    Easy to assemble boltless system
    Installed with adjustable glides for uneven floor`,
    image:staticab,
    sections: [
    { slide: staticab},
    { slide: staticab2},
    { slide: staticab3},
    { slide: staticab4}
    ],
  },
  {
    Link:"360CAB",
    title: "#360CAB",
    description: `#360CAB is the new concept developped to increase the offices storage capacity. Its advantages:`,
    image:mobicab360,
    list:`<li>Space Saving</li> <li>Reducing the "wall linear" (precious area)</li> <li>Decrease by 2 the consultation zones</li> <li>Security: Security key lock, regardless of the number of cabinets side by side(only 1 key).</li> <li>Many possibilities for specific facilities including pull out drawersfor hangingfiles and shelvesfor Box Files.</li>`
  },
 
];
  export default shelvingSystemData

  

  
   
  
  




  

