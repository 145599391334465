import React, { useEffect } from 'react'
import ShelvingSystem from '../Components/ShelvingSystems/ShelvingSystem'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function ShelvingSystemPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
   return (
    <>
    <Navbar/>
    <ShelvingSystem/>
    <Footer/>
    </>
  )
}

export default ShelvingSystemPage