
import { Route, Routes } from 'react-router';
import './App.css';
import HomePage from './Pages/HomePage';
import ShelvingSystemPage from './Pages/ShelvingSystemPage';
import StairliftPage from './Pages/StairliftPage';
import StairliftProductsPage from './Pages/StairliftProductsPage';
import ShelvingSystemProductsPage from './Pages/ShelvingSystemProductsPage';

function App() {
  return (
    <Routes>
      <Route path="/" Component={HomePage} />
      <Route path="/shelving-systems" Component={ShelvingSystemPage}/>
      <Route path="/stairlifts" Component={StairliftPage}/>
      <Route path="/stairlifts-products/:Link" Component={StairliftProductsPage}/>
      <Route path="/shelving-systems-products/:Link" Component={ShelvingSystemProductsPage}/>
    </Routes>
  );
}

export default App;
