import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import StairliftProducts from '../Components/StairLift/StairliftProducts'

function StairliftProductsPage() {
  return (
    <>
    <Navbar/>
    <StairliftProducts/>
    <Footer/></>
  )
}

export default StairliftProductsPage