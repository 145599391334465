import React from 'react'
import banner from "../../images/Stairlifts/staitlift-banner.jpg"
import aboutUs from "../../images/Stairlifts/stairlift-about-us.png"
import cert1 from "../../images/Stairlifts/certificate-1.jpg"
import cert2 from "../../images/Stairlifts/certificate-2.jpg"
import cert3 from "../../images/Stairlifts/certificate-3.jpg"
function Stairlift() {
    return (
        <div className='flex flex-col justify-center items-center gap-[50px] md:text-[12px]'>
            <div className='w-[100%] h-[50vh] md:h-[20vh] relative'>
            <img className='w-[100%] h-[100%] object-cover' src={banner} alt='stairlifts'/>
            <div className='absolute top-[0] flex justify-center items-center bg-[#0000008e] w-[100%] h-[100%] '>
            <h1 className=' text-[4rem] w-[100%] text-center text-white md:text-[30px] '>Stairlifts</h1>
            </div>
            </div>
            <div className=' w-[82%] 2xl:w-[78%] lg:w-[88%] xl:flex-wrap flex justify-between items-center'>
      <div className=' w-[55%] xl:w-[100%] flex flex-col gap-[20px]  '>
      <h1 className='text-[30px] border-b-[5px] md:text-[20px] border-[#0B3A4F] w-[fit-content]'>About Us</h1>
           <p> Albert Massaad® is proud to offer a range of chairlifts designed to provide freedom and independence to those with mobility challenges. Chairlifts are essential for individuals who face difficulties navigating stairs, offering a safe and reliable solution to maintain autonomy within their homes. Albert Massaad® Chairlifts are crafted with precision and attention to detail, ensuring smooth and secure transport between floors. These products help enhance the quality of life, allowing users to move freely and confidently in their own space without needing constant assistance.<br></br><br></br> We understand the importance of maintaining independence while ensuring safety and comfort. Our chairlifts are not just about accessibility; they represent a commitment to improving daily living, ensuring that users can stay in the homes they love without limitations. With innovative technology and sleek designs, our chairlifts blend seamlessly into any environment, providing both function and style.</p>
           
         </div> 
        
         <div className='xl:w-[400px] w-[40%] xl:h-[400px] xl:mt-[2%]'>
         <img className='w-[100%] h-[100%] object-contain' src={aboutUs} alt='stairlifts-aboutus'/>
         </div>
    
           </div>  
          
               
           <div className=' w-[82%] 2xl:w-[78%] lg:w-[88%] flex flex-col justify-start items-start gap-[20px] pb-[5%]'>
        <h1 className='text-[30px] border-b-[5px] md:text-[20px] border-[#0B3A4F] w-[fit-content]'>Quality</h1>
           <p>Albert Massaad operates a quality management system that is ISO 9001: 2015 compliant. ISO 9001:2015 is a globally implemented standard, aimed at satisfying quality requirements and enhancing customer satisfaction in supplier customer relationships.</p>
    <div className=' xl:flex-wrap lg:p-[2%] xl:gap-[20px] flex justify-evenly w-[100%] items-start mt-[2%]'>
        <div className=' h-[100%] flex justify-between flex-col'>
           <div className='h-[40px] lg:h-[35px] bg-[#0B3A4F] px-[2%] text-white'>
             <p className='text-[15px] lg:text-[12px]'>QUALITY MANAGEMENT</p>
             <p className='text-[10px]'>ISO 9001:2008</p>
            </div>
            
             <img className='mt-[10px] object-contain' src={cert1} alt='cert-1' />
           
        </div>
        <div className=' h-[100%] flex justify-between flex-col'>
           <div className='h-[40px] lg:h-[35px] bg-[#0B3A4F] px-[2%] text-white'>
             <p className='text-[15px] lg:text-[12px]'>QUALITY MANAGEMENT</p>
             <p className='text-[10px]'>ISO 9001:2008</p>
            </div>
            
             <img className='mt-[10px] object-contain' src={cert2} alt='cert-2' />
           
        </div>
        <div className=' h-[100%] flex justify-between flex-col'>
           <div className='h-[40px] lg:h-[35px] bg-[#0B3A4F] flex lg:items-center px-[2%] text-white'>
             <p className='text-[15px] lg:text-[12px]'>QUALITY MANAGEMENT</p>
            
            </div>
            
             <img className='mt-[10px] object-contain' src={cert3} alt='cert-3' />
           
        </div>
    
       

    </div>
        </div>
        
            
    
        </div>
      )
}

export default Stairlift