import React from 'react';
import stairliftData from '../StairLift/stairliftdata';
import { useParams } from 'react-router-dom';

function StairliftProducts() {
    const { Link } = useParams();
    const stairlift = stairliftData.find(item => item.Link === Link);

    if (!stairlift) {
        return <div className='py-[3%]'>Stairlift not found</div>;
    }

    return (
        <div className='px-[5%] xl:px-[0%] '>
            <div className='py-[3%] xl:gap-[30px] flex justify-evenly items-start xl:flex-wrap'>
                
                <div className='w-[48%] xl:w-[80%] lg:w-[90%] flex flex-col gap-[20px] text-justify'>
                    <h1 className='text-[30px] border-b-[5px] md:text-[20px] border-[#0B3A4F] w-[fit-content] mb-[3%]'>
                        {stairlift.title}
                    </h1>
                    <p>{stairlift.description}</p>

                   
                    {stairlift.sections && stairlift.sections.map((section, index) => (
                        <div key={index} className=' mt-[2%] xl:w-[100%]'>
                            <h2 className='text-[#0B3A4F]'>{section.title}</h2>
                            <p>{section.content}</p>
                        </div>
                    ))}
                 {stairlift.list && (
                        <div dangerouslySetInnerHTML={{ __html: stairlift.list }} />
                    )}
                </div>

               
                <div className='w-[40%] xl:w-[80%] flex justify-start items-start mt-[5.5%] xl:mt-[0%]'>
                    <img
                        className='w-[100%] h-[100%] object-contain'
                        src={stairlift.image}
                        alt={stairlift.title}
                    />
                </div>
            </div>
        </div>
    );
}

export default StairliftProducts;
