import React from "react";
import background from "../../images/Home/bg.png";
import officeFurniture from "../../images/Home/mobile-shelving.jpg";
import officeLogo from "../../images/Home/Logo-Albert-Massaad.png";
import stairLift from "../../images/Home/stair-lift.jpg";
import stairLiftLogo from "../../images/Home/logo-stair-lift.png"
import { Link } from "react-router-dom";

function AboutUs() {
  return (
    <div className="relative">
      {/* Background Image using CSS */}
      <div
        className="relative w-full h-[80vh] sm:h-[70vh] bg-cover bg-center bg-fixed"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="absolute inset-0 bg-[#3433338f]"></div>
      </div>

      <div className="absolute top-[1%] w-[100%] flex flex-col justify-center items-center py-[2%]">
        <p className="text-[3.5rem] lg:text-[3rem] md:text-[2rem] text-[#0B3A4F] font-thin border-b-[6px] h-[80px] border-[#0B3A4F] mb-[2%]">
          Who Are We
        </p>
        <p className="w-[50%] text-white md:text-[12px] xl:w-[90%] sm:w-[95%] text-center">
          Albert Massaad company was founded in 1963, specialized in the
          manufacturing of steel furniture for private and public sectors.
          Since its creation, albert massaad™ has been dedicated to the design
          and manufacturing of office furniture and medical furniture, while
          providing efficient solutions and services to its Lebanese and the
          Global customers. In 2001, albert massaad™ reached a higher
          international level of professionalism by introducing the mobile
          shelving system. Since 2017, albert massaad™ managed to be the
          authorized dealer of DocuWare in the aim of providing a full solution
          for document management system and workflow.
        </p>
        <div className="w-[90%] md:w-[100%] flex justify-center gap-[5%] p-[2%]">
          <Link to="/shelving-systems" className="w-[230px] bg-[white] flex justify-between items-center flex-col">
            <img
              className="w-[100%] object-contain h-[100%]"
              src={officeFurniture}
              alt="Shelving-system"
            />
            <img
              className="w-[85%] object-contain h-[100%]"
              src={officeLogo}
              alt="Shelving-system-Logo"
            />
            <h1 className="text-[#0B3A4F] py-[5%] md:text-[10px]">Shelving Systems</h1>
          </Link>
          <Link to="/stairlifts" className="w-[230px] bg-[white] flex justify-between items-center flex-col">
            <img
              className="w-[100%] object-contain h-[100%]"
              src={stairLift}
              alt="Stairlifts"
            />
            <img className="w-[25%] object-contain mt-[5%]" src={stairLiftLogo} alt="Stair-Lift-Logo" />
            <h1 className="text-[#0B3A4F] py-[5%] md:text-[10px]">Stairlifts</h1>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
