import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Stairlift from "../Components/StairLift/Stairlift"

function StairliftPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Navbar/>
    <Stairlift/>
    <Footer/>
    </>
  )
}

export default StairliftPage