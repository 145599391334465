import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import '../Home/styles.css';
import { Navigation } from 'swiper/modules';
import shelvingSystemData from '../ShelvingSystems/shelvingsystemdata';
import { useParams } from 'react-router-dom';
import pdf from "../../images/ShelvingSystems/albert-massaad-shelving-system.pdf"

function ShelvingSystemProducts() {
    const { Link } = useParams();
    const shelvingSystem = shelvingSystemData.find(item => item.Link === Link);
 
    const [selectedImage, setSelectedImage] = useState(shelvingSystem?.image);
    useEffect(() => {
        setSelectedImage(shelvingSystem?.image);
    }, [shelvingSystem]);

    if (!shelvingSystem) {
        return <div className='py-[3%]'>The product doesn't exist</div>;
    }
  

    return (
        <div className='px-[5%] xl:px-[0%]  '>
            <div className='py-[3%] xl:gap-[30px] flex justify-evenly items-start xl:flex-wrap'>
                
                <div className='w-[48%] xl:w-[80%] lg:w-[90%] flex flex-col gap-[20px] text-justify'>
                    <h1 className='text-[30px] border-b-[5px] md:text-[20px] border-[#0B3A4F] w-[fit-content] mb-[3%]'>
                        {shelvingSystem.title}
                    </h1>
                    <p>{shelvingSystem.description}</p>
                    {shelvingSystem.sections && 
                    <div className=' h-[35vh] md:h-[30vh] w-[100%] '>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={30}
                            navigation={{ clickable: true }}
                            modules={[Navigation]}
                            breakpoints={{
                                650: {
                                  slidesPerView: 2,
                                },
                              }}
                            className="mySwiper"
                        >
                            {shelvingSystem.sections && shelvingSystem.sections.map((section, index) => (
                                <SwiperSlide key={index}>
                                    <img
                                        src={section.slide}
                                        onClick={() => setSelectedImage(section.slide)}
                                        className="cursor-pointer"
                                        alt={`Slide ${index}`}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                     </div>
                     }
                     {shelvingSystem.list && (
                        <div dangerouslySetInnerHTML={{ __html: shelvingSystem.list }} />
                    )}
                    <div className=' text-white cursor-pointer hover:bg-[#151539a5] bg-[#0B3A4F] text-center flex justify-center items-center w-[100px] h-[40px]'>
                    <a href={pdf} download>Download</a>

                    </div>

                    
                </div>

                <div className='w-[40%] xl:w-[80%] xl:h-[70vh] lg:w-[90%] flex justify-center items-center md:hidden mt-[2%] xl:mt-[0%]'>
                    <img
                        className='w-[100%] h-[100%] object-contain'
                        src={selectedImage}
                        alt={shelvingSystem.title}
                    />
                </div>
                {!shelvingSystem.sections &&
                <div className='w-[40%] xl:w-[80%] lg:w-[90%]  justify-center hidden md:flex items-center my-[5%]'>
                    <img
                        className='w-[100%] h-[100%] object-contain'
                        src={selectedImage}
                        alt={shelvingSystem.title}
                    />
                </div>
}
            </div>
        </div>
    );
}

export default ShelvingSystemProducts;
