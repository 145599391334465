import React from 'react'
import Navbar from '../Components/Navbar'
import ShelvingSystemProducts from '../Components/ShelvingSystems/ShelvingSystemProducts'
import Footer from '../Components/Footer'

function ShelvingSystemProductsPage() {
  return (
    <>
    <Navbar/>
    <ShelvingSystemProducts/>
    <Footer/>
    </>
  )
}

export default ShelvingSystemProductsPage