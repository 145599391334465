import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import Logo from "../images/Home/Black-Logo-Albert-Massaad.png";
import { FaBars } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import "../App.css";

function Navbar() {
  const location = useLocation();
  const [isHoveredShelving, setIsHoveredShelving] = useState(false);
  const [isHoveredStairlift, setIsHoveredStairlift] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToContactUs = (e) => {
    e.preventDefault();
    setMenuOpen(false);
    
    const contactSection = document.getElementById('contact-us');
  
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    } else {
  
      navigate('/', { state: { scrollToContact: true } });
    }
  };
  const isActive = (path) => location.pathname === path;
  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  return (
    <div className=" w-[100%] bg-[white] z-[1] ">
     <div className="bg-[#0B3A4F] h-[25px] text-white flex justify-end gap-[4%] px-[9.2%] xl:px-[10.8%] lg:px-[5%]   ">
  <div className="flex items-center gap-[2%]">
    <MdOutlineMail className="w-[18px] h-[18px]" />
    <a href="mailto:info@ammisr.com" className="text-[12px] sm:text-[10px]">info@ammisr.com</a>
  </div>
  <div className="flex items-center gap-[2%]">
    <FiPhone className="w-[18px] h-[18px]" />
    <a href="tel:+201090155103" className="text-[12px] sm:text-[10px] w-[100%]">+20 109 0155103</a>
  </div>
</div>

      <div className=" lg:hidden h-[80px] justify-between px-[8.5%] flex xl:px-[9.8%]">
        <Link to="/" className="w-[250px] xl:w-[150px]  flex justify-center items-center">
          <img
            className="w-[100%] object-cover "
            src={Logo}
            alt="Logo"
          />
        </Link>
        <div className="items-center w-[44.9%] xl:w-[60%] text-[13px] xl:text-[12px] lg:hidden flex justify-between">
          <Link
            to="/"
            className={`hover:bg-[#0B3A4F] hover:text-white  px-[2%] py-[0.5%]  cursor-pointer ${
              isActive("/") ? "bg-[#0B3A4F] text-white" : ""
            }`}
          >
            HOME
          </Link>
          <div className={`relative z-[50] hover:bg-[#0B3A4F] h-[auto] hover:text-white px-[2%] py-[0.5%] cursor-pointer ${
              isActive("/shelving-systems") ? "bg-[#0B3A4F] text-white" : ""
            }`}
        onMouseEnter={() => setIsHoveredShelving(true)}
        onMouseLeave={() => setIsHoveredShelving(false)}
         >
       <Link to="/shelving-systems"
        className={` hover:text-white`}
        
        >
        SHELVING SYSTEMS
        </Link>
       {isHoveredShelving && (
        <div className="absolute flex flex-col w-[100%] mt-[5px] right-[0%] text-center bg-[#265fba46] ">
        <Link to="/shelving-systems-products/Mobicab"
          className=" py-[3%] w-[100%] hover:bg-[#0B3A4F]">
          Mobicab
        </Link>
        <Link to="/shelving-systems-products/Mobicab-plus"
        className=" py-[3%] w-[100%] hover:bg-[#0B3A4F]  ">
        Mobicab plus
        </Link>
       <Link to="/shelving-systems-products/Mobicab-DD"
          className=" py-[3%] w-[100%] hover:bg-[#0B3A4F]" >
          Mobicab DD
       </Link>
       <Link to="/shelving-systems-products/Staticab"
          className=" py-[3%] w-[100%] hover:bg-[#0B3A4F]">
          Staticab 
        </Link>
        <Link to="/shelving-systems-products/360CAB"
          className=" py-[3%] w-[100%] hover:bg-[#0B3A4F]">
          #360CAB
        </Link>
   
       </div>

          )}
     </div>






     <div className={`relative z-[50] hover:bg-[#0B3A4F] h-[auto] hover:text-white px-[5%] 2xl:px-[7%] py-[0.5%] cursor-pointer ${
          isActive("/stairlifts") ? "bg-[#0B3A4F] text-white" : ""
        }`}
        onMouseEnter={() => setIsHoveredStairlift(true)}
        onMouseLeave={() => setIsHoveredStairlift(false)}
         >
       <Link to="/stairlifts"
        className={` hover:text-white `}
       
        >
        STAIRLIFTS
        </Link>
       {isHoveredStairlift && (
        <div className="absolute flex flex-col w-[100%] mt-[5px] right-[0%] text-center bg-[#265fba46] ">
        <Link to='/stairlifts-products/Flow-II-curved'
          className=" py-[3%] w-[100%] hover:bg-[#0B3A4F]  "
          
        >
          Flow II Curved
        </Link>
        <Link to='/stairlifts-products/Acorn-130-straight'
        className=" py-[3%] w-[100%] hover:bg-[#0B3A4F]  ">
       Acorn 130 Straight
        </Link>
       <Link to='/stairlifts-products/Levant-straight'
          className=" py-[3%] w-[100%] hover:bg-[#0B3A4F]  " >
         Levant Straight
       </Link>
       <Link to='/stairlifts-products/Levant-Straight-Outdoor'
          className=" py-[3%] w-[100%] hover:bg-[#0B3A4F]  " >
         Levant Straight Outdoor
       </Link>
   
       </div>

          )}
     </div>
          <a
            href="#contact-us" onClick={scrollToContactUs}
            className={`hover:bg-[#0B3A4F] hover:text-white px-[2%]   py-[0.5%]  cursor-pointer`}
          >
            CONTACT US
          </a>
        </div>
      </div>

      <div className="lg:flex hidden flex-col shadow-sm px-[3%]">
      <div className="flex justify-between items-center px-[2%] p-4">
        <Link to="/">
          <img src={Logo} className="w-[150px]" alt="Logo" />
        </Link>

        <div className="hidden lg:flex items-center">
          {menuOpen ? (
            <MdOutlineClose
              onClick={toggleMenu}
              style={{ fontSize: 25, color: "#0B3A4F", zIndex: "500" }}
            />
          ) : (
            <FaBars
              onClick={toggleMenu}
              style={{ fontSize: 20, color: "#0B3A4F", zIndex: "500" }}
            />
          )}
        </div>
      </div>

      <div
        className={`fixed inset-0 bg-bg-main transform ${
          menuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out bg-white z-50`}
      >
        <div className="flex flex-col items-center justify-center h-full gap-10 p-5">
          <div className="flex text-[15px] w-[100%] flex-col items-center space-y-6">
            <Link
              to="/"
              className={`hover:bg-[#0B3A4F] px-[2%] py-[0.5%] cursor-pointer ${
                isActive("/") ? "bg-[#0B3A4F] text-white" : ""
              }`}
            >
              HOME
            </Link>

            <div className="flex flex-col justify-center items-center w-[70%] text-center">
              <Link
               to="/shelving-systems"
                className={`hover:bg-[#0B3A4F] px-[2%] py-[0.5%] cursor-pointer ${
                  isActive("/shelving-systems") ? "bg-[#0B3A4F] text-white" : ""
                }`}
              >
                SHELVING SYSTEMS
              </Link>
            
              <Link to="/shelving-systems-products/Mobicab" className={`text-[11px] ${isActive("/shelving-systems-products/Mobicab") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Mobicab</Link>
              <Link to="/shelving-systems-products/Mobicab-plus" className={`text-[11px] pt-[5px] ${isActive("/shelving-systems-products/Mobicab-plus") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Mobicab plus</Link>
              <Link to="/shelving-systems-products/Mobicab-DD" className={`text-[11px] pt-[5px] ${isActive("/shelving-systems-products/Mobicab-DD") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Mobicab DD</Link>
              <Link to="/shelving-systems-products/Staticab" className={`text-[11px] pt-[5px] ${isActive("/shelving-systems-products/Staticab") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Staticab</Link>
              <Link to="/shelving-systems-products/360CAB" className={`text-[11px] pt-[5px] ${isActive("/shelving-systems-products/360CAB") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>#360CAB</Link>
            </div>

            <div className="flex flex-col justify-center items-center w-[100%] text-center">
              <Link
                to="/stairlifts"
                className={`hover:bg-[#0B3A4F] px-[2%] py-[0.5%] cursor-pointer ${
                  isActive("/stairlifts") ? "bg-[#0B3A4F] text-white" : ""
                }`}
              >
                STAIRLIFTS
              </Link>
              <Link to="/stairlifts-products/Flow-II-curved" className={`text-[11px] ${isActive("/stairlifts-products/Flow-II-curved") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Flow II Curved</Link>
              <Link to="/stairlifts-products/Acorn-130-straight" className={`text-[11px] pt-[5px] ${isActive("/stairlifts-products/Acorn-130-straight") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Acorn 130 Straight</Link>
              <Link to="/stairlifts-products/Levant-straight" className={`text-[11px] pt-[5px] ${isActive("/stairlifts-products/Levant-straight") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Levant Straight</Link>
              <Link to="/stairlifts-products/Levant-Straight-Outdoor" className={`text-[11px] pt-[5px] ${isActive("/stairlifts-products/Levant-Straight-Outdoor") ? "bg-[#0B3A4F] text-white w-[fit-content] px-[3%] py-[2%]" : ""}`}>Levant Straight Outdoor</Link>
            </div>

            <a
              href="#contact-us"
              onClick={scrollToContactUs}
              className={`hover:bg-[#0B3A4F] hover:text-white px-[2%] py-[0.5%] cursor-pointer`}
            >
              CONTACT US
            </a>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Navbar;
