 
import flowcurved from "../../images/Stairlifts/Flow-II-curved.jpg"
import levant from "../../images/Stairlifts/Levant-Straight-Outdoor.jpg"
import acorn from "../../images/Stairlifts/acorn130-straight-stairlift.jpg"
import levantStraight from "../../images/Stairlifts/levant-straight.jpg"
 const stairliftData = [{
    Link:"Flow-II-curved",
    title: "Flow II Curved",
    description: `The Flow single rail stairlift is the market leader when it comes to curved stairlifts and is the smartest lift of its type.
    Flow is the only stairlift on the market which is capable of swivelling during the ride both up and down the stairs. This unique and patented feature makes the Flow stairlift capable of fitting most staircases, straight, around corners or even spiral.
    This compact stairlift looks simple on the outside but the technology on the inside is extremely ingenious. The angle of the staircase can vary from -70° up to 70°.`,
    image:flowcurved,
    sections: [
      {
        title: "VERSATILE",
        content: `By using its patented power swivel system, the Flow curved stairlift can tackle staircases as narrow as 61cm (24 inches) wide, swivelling the footrest as it goes. The rail also sits closely to the stairs, making Flow the most adaptable stairlift in the world. No other stairlift can boast this.`,
      },
      {
        title: "COMFORT",
        content: "The Flow curved stairlift has a seat which is ergonomically shaped to the user providing for a more enjoyable ride.",
      },
      {
        title: "AESTHETICALLY DESIGNED",
        content: `The Flow stairlift is designed to be a feature of the home, rather than just a mobility product. The user can choose between three fabric colours, four vinyl, six elegant leather, and three comfort leather to determine the best fit.`,
      },
      {
        title: "CHOICE OF ARMRESTS",
        content: `Suitable for all. The seat height is adjustable to five different positions when a powered swivel is used (set at installation), which means that Flow stairlift can be adjusted to suit a variety of users.`,
      },
      {
        title: "UNOBTRUSIVE",
        content: `Flow folds neatly for storage, using its linked seat-to-footrest mechanism, removing the need to bend and manually fold the stairlift.`,
      },
    ],
  },
  {
    Link:"Levant-Straight-Outdoor",
    title: "Levant Straight Outdoor",
    description: `The Levant Outdoor stairlift provides you with the ultimate in industry-leading weather protection technology. The Levant Outdoor stairlift combines all of the features and functionality of the standard Levant indoor stairlift. It comes with more than 30 completely new components and in excess of 80 specially treated components to provide you with a stairlift that can be used at any time of the year. Levant Outdoor has successfully passed a range of industry accepted corrosion, rain protection and climate testing to ensure reliability and performance.`,
    image:levant,
    sections: [
      {
        title: "MODERN TECHNOLOGY",
        content: `The Levant Outdoor stairlift uses modern technology which ensures that it is quick to install resulting in minimal disruption for the user.`,
      },
      {
        title: "ROBUST",
        content: "Built with quality in mind, the Levant Outdoor was built to last and won't let the user down.",
      },
      {
        title: "EASY TO USE",
        content: `Levant Outdoor is operated with an easy to use constant pressure joystick - just press, hold and go.`,
      },
      {
        title: "AUDIBLE FAULT REPORTING",
        content: `Levant Outdoor will alert the user with a series of beeps if there is a problem.`,
      },
      {
        title: "ELEMENT PROTECTION",
        content: `Levant Outdoor comes with a protective shroud which can be used to cover the lift when it is not in use.`,
      },
    ],
    list:`<li>Speed Max 0.12m/s</li> <li>Drive Type Rack and pinion</li> <li>Motor Wattage 280W</li> <li>Weight Capacity 127-138kg</li> <li>Batteries Used 2*12V batteries,7Ah,Total 24V</li>

    
    
    
    
    
    
    `
  },
  {
    Link:"Acorn-130-straight",
    title: "Acorn 130 straight",
    description: `The Acorn 130 Straight Stairlift is our most affordable option, though it doesn't work on all types of staircases. It is specifically built for straight staircases only.
    It is the perfect choice for anyone with a 2-story home with a straight staircase or a single story home with a basement. The smooth start and stop feature, ensures a safe and comfortable ride`,
    image:acorn,
    list:`<li>The Acorn 130 stairlift fits to the stairs, not the wall</li> <li>Requires no structural changes to your home</li> <li>Perch stairlift option available</li> <li>Affordable and reliable</li> <li>Easily folds away, so everyone can continue to use the stairs</li>
<li>Padded seat and backrest for maximum comfort.</li> <li>Can be operated with the lightest of touches.</li> <li>Perfect for those with limited dexterity.</li> <li>A safety belt is installed on all of our stairlifts, so you feel secure at all times.</li> <li>Our slim fold away design has folding arms, seat and footrest, so other people can continue to use the stairs.</li>
    
<li>A lockable swivel seat means you can get on and off your stairlift without twisting your body.</li> <li>Railing is secured directly to your stairs, not to the wall. So there's no structural damage to your home</li> <li>A lockable on/off switch provides additional child-friendly safety.</li> <li>A diagnostic digital display allows you to see the exact status of the stairlift at all times.</li> <li>Five safety sensors on the footrest and carriage stop the lift automatically should there be any obstruction on your staircase.</li>
<li>Two remote controls are provided so that you can have the convenience of being able to share the stairlift with a loved one.</li>
    
    `
  },
  {
    Link:"Levant-straight",
    title: "Levant Straight",
    description: `The Levant stairlift for straight staircases satisfies the market's need for guaranteed reliability, robust quality and a swift installation without compromising on safety and all at a very competitive price.
    By consulting ergonomic experts and existing stairlift owners the HomeGlide's design is simple, sturdy and meets all accessibility requirements.
    The simplicity of HomeGlide allows the stairlift to be installed very quickly. Fitted directly onto the staircase cleanly and discretely, the stairlift glides effortlessly along an aluminium track.`,
    image:levantStraight,
    sections: [
      {
        title: "INNOVATIVE",
        content: `The HomeGlide straight stairlift uses modern technology which means that it is quick to install resulting in minimal disruption for the user.`,
      },
      {
        title: "ROBUST",
        content: "Built with quality in mind, the HomeGlide stairlift was built to last and wont let the user down.",
      },
      {
        title: "EASY TO USE",
        content: `The HomeGlide stairlift is operated with an easy to use constant pressure joystick just press, hold and go.`,
      },
      {
        title: "CONVENIENT",
        content: `HomeGlide is available with two remote handsets which can be positioned at the top and bottom of the stairs allowing for multiple users in the same household.`,
      },
      {
        title: "AVOIDS OBSTACLES",
        content: `The HomeGlide straight rail can be fitted with a powered hinge which can be used when the rail comes close to a door or similar obstacle.`,
      },
      {
        title: "COST EFFECTIVE",
        content: `HomeGlide stairlift provides the tools to meet the challenges of straight staircases, without a need for extra options, resulting in a less expensive stairlift`,
      },
      {
        title: "AUDIBLE FAULT REPORTING",
        content: `HomeGlide will alert the user with a series of beeps if there is a problem.`,
      },
      {
        title: "SMOOTH FINISH",
        content: `The HomeGlide straight rail can be cut at an angle to make the rail finish flat to the floor, reducing the distance from the footrest to the floor.`,
      },
    ],
  },
];
  export default stairliftData

  

  
   
  
  




  

