import React, { useEffect, useState } from "react";
import { MdOutlineMail } from "react-icons/md";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactUs() {
  const location = useLocation();
const [loading, setLoading]= useState(false)
  useEffect(() => {
    if (location.state?.scrollToContact) {
      const contactSection = document.getElementById('contact-us');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const sendEmail = (e) => {
    setLoading(true);
    e.preventDefault();
    const form = e.target;
  
    const formData = {
      fname: form.fname.value,
      lname: form.lname.value,
      email: form.email.value,
      subject: form.subject.value,
      to_name: `${form.fname.value} ${form.lname.value}`,
    };
  
    // Sending the email to Albert Massaad
    emailjs.send("service_u4peufe", "template_reo6fmu", formData, "-aLQ3uRX3aVBjKxS9")
      .then(() => {
        // Sending confirmation email to the client
        return emailjs.send("service_u4peufe", "template_7cv8gsu", {
          from_name: "Albert Massaad",
          to_name: `${formData.fname} ${formData.lname}`,
          to_email: formData.email,
        }, "-aLQ3uRX3aVBjKxS9");
      })
      .then(() => {
        toast.success("Your message has been sent successfully! We will get back to you shortly.");
        setLoading(false);
        form.reset();
      })
      .catch(() => {
        toast.error("Oops! Something went wrong while sending your message. Please try again later.");
        setLoading(false); 
      });
  };
  

  return (
    <div id="contact-us" className="p-[3%] md:justify-end w-[100%] flex flex-col justify-center items-center bg-[#eeeeee]">
      <p className="text-[3.5rem] lg:text-[3rem] md:text-[2rem] text-[#0B3A4F] font-thin border-b-[6px] h-[80px] border-[#0B3A4F]">
        Get in Touch
      </p>

      <div className="w-[100%] flex justify-center gap-[5%] md:flex-wrap p-[2%]">
        <form className="w-[30%] xl:w-[50%] md:w-[80%] justify-center gap-[3%] flex flex-col" onSubmit={sendEmail}>
          <div className="flex justify-center md:flex-col gap-[1%] w-[100%]">
            <div className="w-[50%] md:w-[100%] flex gap-[2px] flex-col">
              <label className="text-[#0B3A4F]">First Name</label>
              <input name="fname" className="h-[50px] p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" required />
            </div>
            <div className="w-[50%] md:w-[100%] flex gap-[2px] flex-col">
              <label className="text-[#0B3A4F]">Last Name</label>
              <input name="lname" className="h-[50px] p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" required />
            </div>
          </div>
          <div className="w-[100%] flex gap-[2px] flex-col">
            <label className="text-[#0B3A4F]">Email</label>
            <input name="email" type="email" className="h-[50px] p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" required />
          </div>
          <div className="w-[100%] flex gap-[2px] flex-col">
            <label className="text-[#0B3A4F]">Message</label>
            <textarea name="subject" className="h-[100px] p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" required />
          </div>
          <div className="flex justify-end pt-[2%] md:my-[2%]">
            
          <button
  type="submit"
  className={`bg-[#0B3A4F] text-white w-[100px] p-[1%] rounded-md ${
    loading ? 'opacity-50 cursor-not-allowed' : ''
  }`}
  disabled={loading}
>
  {loading ? 'Loading...' : 'Send'}
</button>

          </div>
        </form>
        <div className="border-[1px]flex flex-col justify-between p-4 md:p-0 md:w-[80%]">
          <p className="font-thin text-[25px] text-[#0B3A4F]">Stay Connected</p>
          <p className="text-[1vw] xl:text-[1.5vw] lg:text-[12px] font-thin">
            Send us your question or contact our divisions below:
          </p>

          <div className="my-[30px] ">
            <p className="text-[#0B3A4F]">Stairlift</p>
            <div className="flex items-center gap-1">
              <MdOutlineMail className="w-[18px] text-[#0B3A4F] h-[25px]" />
              <a
                href="mailto:Diana@albertmassaad.com"
                className="text-gray-800 text-[12px] hover:text-[gray]"
                target="_blank"
                rel="noopener noreferrer"
              >
                Diana@albertmassaad.com
              </a>
            </div>
            <div className="flex items-center gap-1">
              <AiOutlineWhatsApp className="w-[18px] text-[#0B3A4F] h-[25px]" />
              <a
                href="https://wa.me/9611895177"
                className="text-gray-800 text-[12px] hover:text-[gray]"
                target="_blank"
                rel="noopener noreferrer"
              >
                01895177
              </a>
            </div>
          </div>

          <div className="my-[30px] ">
            <p className="text-[#0B3A4F]">Shelving Systems:</p>
            <div className="flex items-center gap-1">
              <MdOutlineMail className="w-[18px] text-[#0B3A4F] h-[25px]" />
              <a
                href="mailto:Joanna@albertmassaad.com"
                className="text-gray-800 text-[12px] hover:text-[gray]"
                target="_blank"
                rel="noopener noreferrer"
              >
                Joanna@albertmassaad.com
              </a>
            </div>
            <div className="flex items-center gap-1">
              <AiOutlineWhatsApp className="w-[18px] text-[#0B3A4F] h-[25px]" />
              <a
                href="https://wa.me/9611901502"
                className="text-gray-800 text-[12px] hover:text-[gray]"
                target="_blank"
                rel="noopener noreferrer"
              >
                01901502
              </a>
            </div>
          </div>

          {/* <div className="my-[30px] ">
            <p className="text-[#0B3A4F]">Office Division:</p>
            <div className="flex items-center gap-1">
              <MdOutlineMail className="w-[18px] text-[#0B3A4F] h-[25px]" />
              <a
                href="mailto:mailto@office-division.com"
                className="text-gray-800 text-[12px] hover:text-[gray]"
                target="_blank"
                rel="noopener noreferrer"
              >
                mailto@office-division.com
              </a>
            </div>
            <div className="flex items-center gap-1">
              <MdOutlineMail className="w-[18px] text-[#0B3A4F] h-[25px]" />
              <a
                href="mailto:dominique@office-division.com"
                className="text-gray-800 text-[12px] hover:text-[gray]"
                target="_blank"
                rel="noopener noreferrer"
              >
                dominique@office-division.com
              </a>
            </div>
            <div className="flex items-center gap-1">
              <AiOutlineWhatsApp className="w-[18px] text-[#0B3A4F] h-[25px]" />
              <a
                href="https://wa.me/96170765452"
                className="text-gray-800 text-[12px] hover:text-[gray]"
                target="_blank"
                rel="noopener noreferrer"
              >
                70765452
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
